.App-aboutme {
  .aboutme-image {
    transform: rotateY(180deg);
  }

  @media screen and (max-width: calc(#{map-get($grid-breakpoints, "md")} - 1px)) {
    .aboutme-image {
      display: none;
    }
  }
}
